<template>
  <div class="doc" ref="docWrap">
    <div v-if="!isError" class="bread van-hairline--top van-hairline--bottom">
      <router-link to="/" :class="{ 'with-suffix': contentDoc.length > 0 }"
        >首页</router-link
      >
      <template v-if="contentDoc.length > 0">
        <span class="with-suffix">{{ levelFirst }}</span>
        <span>{{ levelSecond }}</span>
      </template>
    </div>
    <div class="doc-content">
      <div
        @click="handleMenu"
        v-if="!isError"
        class="menu-btn van-hairline--surround"
      >
        <i class="iconfont icon-menu-dot"></i>
        <span>目录</span>
      </div>
      <van-skeleton title :row="5" :loading="loading">
        <article class="markdown-body" v-html="markHtml"></article>
        <van-empty v-if="isError" :description="errorText" />
      </van-skeleton>
    </div>
    <van-popup
      v-model="menuShow"
      position="left"
      closeable
      :close-icon="close"
      :style="{ width: '72%', height: '100%' }"
    >
      <h3 class="menu-title van-hairline--bottom">目录</h3>
      <div class="nav">
        <ul class="nav-list">
          <li
            v-for="(nav, index) of contentDoc"
            :key="nav.category"
            :class="{ active: curParentIndex === index }"
            class="nav-item"
          >
            <div class="nav-wrap" @click="handleNavClick(nav, index)">
              <p>{{ nav.category }}</p>
              <i
                class="arrow"
                :class="{ down: curIndex === index }"
                v-if="nav.items"
              ></i>
            </div>
            <ul
              class="nav-child"
              v-if="nav.items"
              :class="{ close: curIndex !== index }"
            >
              <li
                v-for="child of nav.items"
                :key="child.documentId"
                @click="handleSecondClick(index, child)"
                :class="{ current: curItemId === child.documentId }"
              >
                <span>{{ child.name }}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
import pageMixin from '@/mixins/pageMixin'
import showdown from 'showdown'
import { mapActions, mapGetters } from 'vuex'
import close from '~/assets/images/icon_close.png'
export default {
  components: {
    // subBanner
  },
  mixins: [pageMixin],
  data () {
    return {
      close,
      isError: false,
      errorText: '暂无数据',
      loading: false,
      markHtml: '',
      menuShow: false,
      curParentIndex: '',
      curIndex: 0,
      curItemId: '',
      levelFirst: '',
      levelSecond: ''
    }
  },
  created () {
    this.fetchContentByKey('Doc')
  },
  mounted () {
    if (this.contentDoc.length > 0) {
      this.getInitDoc(this.contentDoc)
    }
  },
  watch: {
    contentDoc (val) {
      this.getInitDoc(val)
    }
  },
  computed: {
    ...mapGetters(['contentDoc'])
  },
  methods: {
    ...mapActions(['fetchContentByKey']),
    convertMD (data) {
      const converter = new showdown.Converter()
      converter.setOption('tables', true)
      const text = data.toString()
      this.markHtml = converter.makeHtml(text)
    },
    handleNavClick (nav, index) {
      this.curIndex = this.curIndex === index ? -1 : index
    },
    handleSecondClick (index, child) {
      if (this.curItemId === child.documentId) return
      this.curParentIndex = index
      this.curItemId = child.documentId
      this.levelFirst = this.contentDoc[index].category
      this.levelSecond = child.name
      this.toTop()
      this.changeQueryId()
      this.fetchDocDetail()
      setTimeout(() => {
        this.handleMenu()
      }, 200)
    },
    // 每次切换文档回顶部
    toTop () {
      this.$refs.docWrap.scrollIntoView()
    },
    getInitDoc (val) {
      if (val.length > 0) {
        if (this.$route.query.doc) {
          this.curItemId = this.$route.query.doc
          for (const i in val) {
            for (const j of val[i].items) {
              if (this.$route.query.doc === j.documentId) {
                const _i = Number(i)
                this.curIndex = _i
                this.levelFirst = val[_i].category
                this.levelSecond = j.name
                break
              }
            }
          }
        } else {
          if (val[0].items.length > 0) {
            this.curItemId = val[0].items[0].documentId
            this.curParentIndex = 0
            this.levelFirst = val[0].category
            this.levelSecond = val[0].items[0].name
            this.changeQueryId()
          }
        }
        this.curItemId && this.fetchDocDetail()
      }
    },
    changeQueryId () {
      this.$router.push({
        query: {
          doc: this.curItemId
        }
      })
    },
    fetchDocDetail () {
      this.loading = true
      this.api
        .fetchDocDetailContent(this.curItemId)
        .then(data => {
          this.convertMD(data)
        })
        .catch(error => {
          this.isError = true
          console.log(error)
          if (error === 404) {
            this.errorText = '请检查文档id是否正确'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleMenu () {
      this.menuShow = !this.menuShow
    }
  }
}
</script>

<style lang="less" scoped>
@breadHeight: 55px;
/deep/ .van-popup__close-icon--top-right {
  top: 38px;
  right: 40px;
  .van-icon__image {
    width: 26px;
    height: 26px;
  }
}
.doc {
  .bread {
    position: sticky;
    top: @header-height;
    left: 0;
    z-index: 1;
    width: 100%;
    height: @breadHeight;
    line-height: @breadHeight;
    .textEllipsisSingle;
    padding: 0 30px;
    background: #fff;
    font-size: 26px;
    color: @grey;
    .with-suffix {
      color: @black;
      &::after {
        content: "/";
        margin: 0 10px;
      }
    }
  }
  .doc-content {
    padding: 30px;
    min-height: 60vh;
    .menu-btn {
      .flex;
      width: 140px;
      line-height: 55px;
      margin-bottom: 25px;
      background: #f5f9ff;
      font-size: 26px;
      i {
        font-size: 25px;
        margin-right: 10px;
      }
    }
    /deep/ .markdown-body {
      width: 100%;
      overflow: hidden;
      font-size: 24px;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 24px;
        margin-bottom: 20px;
        font-weight: 600;
        line-height: 1.25;
      }
      h1,
      h2 {
        padding-bottom: 0.5em;
        border-bottom: 1px solid #eaecef;
        font-size: 1.3em;
      }
      h1 {
        font-size: 1.6em;
      }
      ul,
      ol {
        padding-left: 2em;
        list-style-type: disc;
      }
      ul ul {
        list-style-type: circle;
      }
      ul ul ul {
        list-style-type: square;
      }
      ul li {
        line-height: 1.5;
      }
      blockquote,
      details,
      dl,
      ol,
      p,
      pre,
      table,
      ul {
        margin-top: 0;
        margin-bottom: 16px;
      }
      em {
        font-style: normal;
      }
      p {
        line-height: 1.5;
      }
      img {
        max-width: 100%;
        margin: 20px 0;
      }
      pre {
        padding: 20px;
        overflow: auto;
        line-height: 1.45;
        background-color: #f6f8fa;
        border-radius: 3px;
      }
      table {
        display: block;
        width: 690px;
        overflow: auto;
        thead tr {
          background-color: #f6f8fa;
        }
        tr {
          &:nth-child(even) {
            background-color: #f6f8fa;
          }
        }
        td,
        th {
          padding: 15px 15px;
        }
        td {
          line-height: 32px;
        }
      }
    }
  }
  .menu-title {
    font-size: 32px;
    padding: 40px 0 30px 30px;
  }
  .nav {
    padding-top: 60px;
    .nav-child {
      text-indent: 0px;
      li {
        line-height: 44px;
        padding: 16px 0 16px 106px;
        height: auto;
      }
      .current {
        color: @blue;
      }
    }
  }
}
</style>
